import {Button, Div, Divider, Flex, Hidden, Navigator, SnackbarService, String, TabPanel} from "@hps/hops-react";
import {CheckoutBasketItem, OrderableTypes, OrderQuestionService, PosCustomerDetailsCollectionMode, PosCustomerDetailsType, PriceUtils, SeatReservationUtils, SystemItemTypes} from "@hps/hops-sdk-js";
import {Badge} from "@mui/material";
import React, {useState} from "react";

import Permissions from "App/Permissions.js";
import dPaymentType from "Dispatchers/dPaymentType.js";
import dQuestionsDialog from "Dispatchers/dQuestionsDialog.js";
import dRefund from "Dispatchers/dRefund.js";
import withAuthUser from "Hoc/withAuthUser.js";
import withBasket from "Hoc/withBasket.js";
import withReducedFunctionality from "Hoc/withReducedFunctionality.js";
import withUi from "Hoc/withUi.js";
import PaymentMethodButtons from "Payment/PaymentMethodButtons.js";
import BasketService from "Services/BasketService.js";

import BasketExpirationTimer from "./BasketExpirationTimer.js";
import BasketPaneItem from "./BasketPaneItem.js";
import BasketRedemptionDialog from "./BasketRedemptionDialog.js";

import NoSaleIcon from "@mui/icons-material/BrowserNotSupported";
import ClearIcon from "@mui/icons-material/Clear";
import RefundIcon from "@mui/icons-material/CurrencyExchange";
import DiscountIcon from "@mui/icons-material/LocalOfferOutlined";
import QuestionIcon from "@mui/icons-material/QuestionAnswer";

import scss from "./BasketPane.module.scss";

/**
 * Renders the basket sidebar pane
 * 
 * @package HOPS
 * @subpackage Basket
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const BasketPane = props => {

	const [redemptionDialogOpen, setRedemptionDialogOpen] = useState(false);
	const handleCloseRedemptionDialog = () => setRedemptionDialogOpen(false);
	const handleOpenRedemptionDialog = () => setRedemptionDialogOpen(true);

	/**
	 * Get whether we're in a disabled state.
	 * 
	 * @return {Boolean}
	 */
	const disabled = props.BasketLoading;

	const unansweredQuestionsCount = () => {

		/** Answered questions */
		let answersCount = OrderQuestionService.getAnswersCount(
			props.BasketQuestions,
			props.BasketQuestionsAnswers,
			props.BasketItems
		);

		/** Questions the user has declined to answer */
		const optionalNoAnswerAcknowledgementsCount = Object.values((props.BasketQuestionsAnswersDeclined || {})).filter(v => v).length;

		/** Customer details inputs */
		for (const customerDetailsType of Object.keys(props.allowedCustomerDetails).map(i => parseInt(i))) {
			if (props.allowedCustomerDetails[customerDetailsType] === PosCustomerDetailsCollectionMode.Required) {
				if (customerDetailsType === PosCustomerDetailsType.Address) {
					const address = props.BasketCustomerDetails?.Address;
					if (address && Object.values(address).every(a => a)) {
						answersCount++;
					}
				}
				else if (customerDetailsType === PosCustomerDetailsType.Email) {
					if (props.BasketCustomerDetails?.Email) {
						answersCount++;
					}
				}
				else if (customerDetailsType === PosCustomerDetailsType.Name) {
					if (props.BasketCustomerDetails?.Fname && props.BasketCustomerDetails?.Sname) {
						answersCount++;
					}
				}
				else if (customerDetailsType === PosCustomerDetailsType.Phone) {
					if (props.BasketCustomerDetails?.Phone) {
						answersCount++;
					}
				}
			}
		}

		/** Get the actual count of questions the user hasn't interacted with */
		return Math.max((questionsCount - (answersCount + optionalNoAnswerAcknowledgementsCount)), 0);

	};

	const hasQuestions = !!props.BasketQuestions?.Questions?.length || props.hasAllowedCustomerDetails;
	// const hasUnansweredQuestions = (hasQuestions && !!unansweredQuestionsCount());
	const customerDetailsCount = Object.keys(props.allowedCustomerDetails).filter(i => (props.allowedCustomerDetails[i] === PosCustomerDetailsCollectionMode.Required)).length;
	const orderQuestionsCount = OrderQuestionService.getQuestionsCount(props.BasketQuestions, props.BasketItems);
	const questionsCount = (customerDetailsCount + orderQuestionsCount);

	const hasItems = !!props.BasketItems?.length;

	const [activeControlsTab, setActiveControlsTab] = useState(0);


	/**
	 * Going to checkout.
	 *
	 * We update the state to select the payment type selected by 
	 * the checkout button press, then navigate to the checkout view.
	 *
	 * We do not allow checkout until we know seat reservation is complete.
	 * 
	 * @param {Integer} PaymentType Selected payment type ID
	 * @return {void}
	 */
	const handleCheckout = (PaymentType, SkipConfirmation) => {
		if (!Object.values(SeatReservationUtils.validateBasketSelection(props.BasketItems)).every(i => ((i === true) || (i === undefined)))) {
			SnackbarService.snack("There's a basket item with an incomplete seating selection.", "warning");
		}
		else if (hasQuestions && unansweredQuestionsCount()) {
			SnackbarService.snack("You must complete the order questions before checkout.", "warning");
			dQuestionsDialog(true);
		}
		else {
			dPaymentType(PaymentType);
			if (SkipConfirmation === true) Navigator.navigate("/checkout?skipconfirm=1");
			else Navigator.navigate("/checkout");
		}
	};

	/**
	 * Process a no-sale.
	 */
	const handleNoSale = async () => {

		// Button should be gated by no-transaction-in-progress, but reset everything anyway.
		BasketService.clearItems();

		// Add a system (10) no-sale item (3)
		await BasketService.addItems([
			CheckoutBasketItem.construct({
				OrderableType: OrderableTypes.System,
				Item: {
					Id: SystemItemTypes.NoSale
					// Description: Future provision to collect a reason from operator
				},
				Price: 0,
				Quantity: 1,
				VatProportion: 0,
				VatRate: 0
			})
		]);

		// Checkout
		handleCheckout(null, true);

	};

	/**
	 * Render basket content.
	 *
	 * @return {ReactNode}
	 */
	const renderBasket = () => {

		const items = props.BasketItems?.filter(i => !CheckoutBasketItem.isInternal(i));
		const seatingValidity = SeatReservationUtils.validateBasketSelection(props.BasketItems);

		return (
			<Flex className={scss.containerBasket}>
				<Flex className={scss.containerBasketItems} gap={0}>
					{
						items.map((item, key) => (
							<BasketPaneItem
								disabled={disabled}
								item={item}
								pendingSeatSelection={(seatingValidity[item.Uuid] === false)}
								key={key} />
						))
					}
					{(!items.length && renderEmpty())}
				</Flex>
				<Divider/>
				<Flex px={1}>
					{(hasQuestions && renderQuestionsButton())}
					<Flex gap={0}>
						<Hidden hidden={!props.BasketTotalDiscounts}>
							<Flex
								alignItems="center"
								columnar={true}
								justifyContent="space-between">
								<String
									color="error"
									noFlex={true}
									str="Discounts" />
								<String
									bold={true}
									color="error"
									noFlex={true}
									str={PriceUtils.getDisplayStringIntl(props.BasketTotalDiscounts * -1)} />
							</Flex>
						</Hidden>
						<Flex
							alignItems="center"
							columnar={true}
							justifyContent="space-between">
							<String
								noFlex={true}
								str="Sub Total"
								variant="h6" />
							<String
								bold={true}
								noFlex={true}
								str={PriceUtils.getDisplayStringIntl(props.BasketTotal)}
								variant="h6" />
						</Flex>
						<Hidden hidden={props.PaymentsReceived === 0}>
							<Flex
								alignItems="center"
								columnar={true}
								justifyContent="space-between">
								<String
									noFlex={true}
									str="Received" />
								<String
									bold={true}
									noFlex={true}
									str={PriceUtils.getDisplayStringIntl(props.PaymentsReceived)} />
							</Flex>
						</Hidden>
						<Flex
							alignItems="center"
							columnar={true}
							justifyContent="space-between">
							<String
								color={!props.PaymentsIsSettled ? "error" : undefined}
								noFlex={true}
								str="Balance Due" />
							<String
								bold={true}
								color={!props.PaymentsIsSettled ? "error" : undefined}
								noFlex={true}
								str={PriceUtils.getDisplayStringIntl(props.PaymentsBalanceDue)} />
						</Flex>
					</Flex>
					<PaymentMethodButtons
						disabled={disabled}
						compactUi={true}
						onChangePaymentType={handleCheckout}
						showPaymentMethods={(!props.PaymentsIsSettled && props.BasketTotal > 0)}
						showRefundMethods={(!props.PaymentsIsSettled && props.BasketTotal < 0)} />
				</Flex>
			</Flex>
		);
	};


	/**
	 * Render the basket control buttons.
	 * 
	 * @return {ReactNode}
	 */
	const renderBasketControls = () => {
		return (
			<Flex py={1} px={1}>
				<Flex
					columnar={true}
					justifyContent="space-between"
					gap={0.5}>
					<Button
						ButtonProps={{sx: {flexGrow: 1}}}
						disabled={disabled || props.hasChargedPayments}
						label="Void All"
						onClick={BasketService.clearItems}
						startIcon={ClearIcon} />
					{!props.AlwaysOffline && <Button
						ButtonProps={{sx: {flexGrow: 1}}}
						disabled={disabled || props.hasPayments || props.ReducedFunctionality}
						label="Discount"
						onClick={handleOpenRedemptionDialog}
						startIcon={DiscountIcon} />}
				</Flex>
			</Flex>
		);
	};


	/**
	 * Render the supervisor control buttons.
	 * 
	 * @return {ReactNode}
	 */
	const renderSupervisorControls = () => {
		return (
			<Flex py={1} px={1}>
				<Flex gap={0.5}>
					<Button
						ButtonProps={{sx: {flexGrow: 1}}}
						color="error"
						disabled={disabled || hasItems} // Can only no-sale if a transaction is not in progress
						label="No Sale"
						onClick={handleNoSale}
						startIcon={NoSaleIcon}
						variant="outlined" />
					{!props.AlwaysOffline && <Hidden hidden={!props.hasPermission(Permissions.P525_QUICK_REFUND_IN_POS)}>
						<Button
							ButtonProps={{sx: {flexGrow: 1}}}
							color="error"
							disabled={disabled || props.hasPayments || props.ReducedFunctionality || !props.hasPermission(Permissions.P525_QUICK_REFUND_IN_POS)}
							label="Product Refund"
							onClick={() => dRefund(!props.Ui?.Refund)}
							startIcon={RefundIcon}
							variant={props.Ui?.Refund ? "contained" : "outlined"} />
					</Hidden>}
				</Flex>
			</Flex>
		);
	};


	/**
	 * Render the questions button.
	 *
	 * @return {ReactNode}
	 */
	const renderQuestionsButton = () => {

		const unansweredCount = (unansweredQuestionsCount() || 0);

		return (
			<Button
				disabled={disabled}
				label="Questions"
				onClick={dQuestionsDialog}
				size="large"
				startIcon={<Badge badgeContent={unansweredCount} color="error" invisible={!unansweredCount}><QuestionIcon /></Badge>}
				value={true} />
		);

	};


	/**
	 * Render the empty state.
	 * 
	 * @return {ReactNode}
	 */
	const renderEmpty = () => {
		return (
			<Flex
				alignItems="center"
				justifyContent="center"
				flexGrow={1}>
				<String
					color="textSecondary"
					str="No Items"
					variant="h6" />
			</Flex>
		);
	};

	const tabs = [
		{
			label: "Basket",
			render: renderBasketControls
		},
		{
			label: "Supervisor",
			render: renderSupervisorControls
		}
	].filter(t => !t.hidden);

	return <>
		<Div className={scss.paper} variant="outlined">

			<Flex
				gap={0}
				className={scss.container}
				py={1}>
				<Flex gap={0}>

					<TabPanel
						gap={0}
						onChange={setActiveControlsTab}
						tabs={tabs}
						scrollButtons="auto"
						value={activeControlsTab}
						variant="scrollable" />

					<Hidden hidden={!hasItems}>
						<Flex
							alignItems="center"
							columnar={true}
							justifyContent="space-between"
							px={1}
							py={0.5}>
							<String
								color="textSecondary"
								noFlex={true}
								str="Basket expires in" />
							<BasketExpirationTimer />
							{!props.AlwaysOffline && <Hidden hidden={!props.ReducedFunctionality}>
								<String
									color="error"
									noFlex={true}
									str="Offline" />
							</Hidden>}
						</Flex>
					</Hidden>

					<BasketRedemptionDialog
						onClose={handleCloseRedemptionDialog}
						open={redemptionDialogOpen} />

				</Flex>
				<Divider />
				{(hasItems ? renderBasket() : renderEmpty())}
			</Flex>
		</Div>
	</>;


};

export default withAuthUser(withBasket(withReducedFunctionality(withUi(BasketPane))));
